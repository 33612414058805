<template>
  <b-modal
    id="modalPreviewForm"
    hide-footer
    centered
    size="lg"
    :title="form.name"
  >
    <Preview v-if="form && form.formElements" :form="form" />
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import Preview from '@/views/forms/Preview'

export default {
  components: {
    Preview,
  },
  computed: {
    ...mapGetters({
      form: 'forms/getForm',
    }),
  }
}
</script>